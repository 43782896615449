import React from "react";
import { Carousel } from 'antd';

const items = [{
    key: 1,
    title: "Bienvenido a nuestro portal",
    content: "REINTECH Consulting es su asociado de negocio...",
    closing: "¡Juntos trabajamos mejor!"
}]

function AppPortrait() {
    return (
        <div id="home" className="heroBlock">
            <Carousel>
                {
                    items.map(item => {
                        return (
                            <div key={item.key} className="container-fluid">
                                <div className="content">
                                    <h2 style={{color:'#fdfdfd'}}>{item.title}</h2>
                                    <br/>
                                    <h3 style={{color:'#fdfdfd'}}>{item.content}</h3>
                                    <h2 style={{color:'#fdfdfd'}}>{item.closing}</h2>
                                </div>
                            </div>
                        );
                    })
                }
            </Carousel>
        </div>
    );
}

export default AppPortrait;