import React from "react";

import { Card, Row, Col } from "antd";

function AppServices() {
  return (
    <div id="service" className="block featureBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2 style={{ color: "#19c95d" }}>Nuestros Servicios</h2>
        </div>
        <div className="widthBlock">
          <Row gutter={[16, 16]}>
            <Col md={{ span: 12 }}>
              <Card
                title="Consultoría, Sesiones de Descubrimiento y de Diseño"
                bordered={false}
              >
                Brindamos sesiones personalizadas usando frameworks y buenas
                prácticas para generar eficiencias y co-crear soluciones de
                valor.//Servicio de Datos e Inteligencia Artificial.
              </Card>
            </Col>
            <Col md={{ span: 12 }}>
              <Card
                title="Servicio de Datos e Inteligencia Artificial"
                bordered={false}
              >
                Ayudamos a resolver sus problemas y satisfacer sus necesidades
                gestionando los datos y utilizando inteligencia artificial.
              </Card>
            </Col>
            <Col md={{ span: 12 }}>
              <Card
                title="Modernización de Infraestructura y Aplicaciones"
                bordered={false}
              >
                Lo acompañaremos en su viaje de transformación digital con
                servicios de modernización de nube híbrida.
              </Card>
            </Col>
            <Col md={{ span: 12 }}>
              <Card
                title="Fabrica de Software: Cloud Native, Mobile"
                bordered={false}
              >
                Desarrollo de aplicaciones confiables a medida, costo-eficientes
                con las mejores tecnologías de la industria.
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default AppServices;
