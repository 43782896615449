import React from "react";

import { Collapse } from "antd";

const { Panel } = Collapse;

const data = [
  {
    key: "1",
    header: "¿Qué es Transformación Digital?",
    content:
      "Es un proceso de reinvención del modelo de negocio de las organizaciones que adopta distintas tecnologías y soluciones digitales que genera innovación, mejor experiencia del cliente y eficiencias que involucran tanto la cultura, procesos y tecnologías de la empresa.",
  },
  {
    key: "2",
    header: "¿Qué es Modernización?",
    content:
      "La modernización de infraestructura y de aplicaciones va más allá de sustituir componentes por uno más modernos, es buscar eficiencias y un impacto positivo en  los objetivos y la cultura de la organización.",
  },
  {
    key: "3",
    header: "¿Qué es la nube?",
    content:
      "Es un conjunto de servicios tanto de infraestructura, plataforma y software, en primer lugar, que permiten brindar soluciones seguras, escalables, altamente disponibles y resilientes, que generan valor para el negocio de las organizaciones.",
  },
];

function AppFaq() {
  return (
    <div id="faq" className="block faqBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2 style={{ textAlign: "center", color: "#19c95d" }}>
            Preguntas Frecuentes
          </h2>
        </div>
        <Collapse defaultActiveKey={["1"]}>
          {data.map((item) => {
            return (
              <Panel header={item.header} key={item.key}>
                <p>{item.content}</p>
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
}

export default AppFaq;
