import React from "react";

import { Button, Col, Form, Input, Row } from "antd";

function AppContact() {
  return (
    <div id="contact" className="block contactBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2 style={{ color: "#19c95d" }}>Comunícate con Nosotros</h2>
        </div>
        <Form>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Ingrese su nombre",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Nombre" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Correo invalido",
              },
              {
                required: true,
                message: "Ingrese su correo",
              },
            ]}
          >
            <Input placeholder="Correo" />
          </Form.Item>

          <Form.Item
            name="message"
            rules={[{ required: true, message: "Ingrese su mensaje" }]}
          >
            <Input.TextArea
              showCount
              maxLength={100}
              placeholder="Ingrese su mensaje"
            />
          </Form.Item>

          <Form.Item
            label="Captcha"
            extra="We must make sure that your are a human."
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="captcha"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Please input the captcha you got!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button>Get captcha</Button>
              </Col>
            </Row>
          </Form.Item>
          <div style={{ color: "#fdfafa" }}>
            Este sitio está protegido por reCAPTCHA y aplica la Política de
            privacidad y los Términos de servicio de REINTECH. <br /> <br />
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" >
              Register
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AppContact;
