import React from "react";
//Se debe aplicar una lista cuando se aumenten clientes
import { List, Card } from "antd";
import client1 from "../../assets/images/agrihusac.jpg";

const data = [
  {
    title: "AGRIHUSAC",
    alt: "AGRIHUSAC",
    solution:
      "Implementación de una solución de automatización para generar eficiencias en la producción de empacado de paltas y cítricos.",
    firma: "Gerente General - 20/07/2022",
  },
];

function AppClients() {
  return (
    <div id="clients" className="block worksBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2 style={{ color: "#19c95d" }}>Nuestros Clientes</h2>
        </div>
        <div className="contentHolder">
          <List
            grid={{
              gutter: 16,
              column: 1
            }}
            dataSource={data}
            renderItem={(item) => (
              <Card title={item.title} style={{ width: "300px" }}>
                <img
                  src={client1}
                  alt={item.alt}
                  style={{ width: "50%", height: "50%" }}
                />
                <p>{item.solution}</p>
                <p>{item.firma}</p>
              </Card>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default AppClients;
