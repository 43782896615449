import React from "react";

import AppPortrait from "../components/home/portrait";
import AppAbout from "../components/home/about";
import AppServices from "../components/home/services";
import AppClients from "../components/home/clients";
import AppFaq from "../components/home/faq";
import AppContact from "../components/home/contact";

function AppHome() {
    return(
        <div className="main">
            <AppPortrait/>
            <AppAbout/>
            <AppServices/>
            <AppClients/>
            <AppFaq/>
            <AppContact/>
        </div>
    );
}

export default AppHome;