import React from "react";
import { Row, Col } from "antd";
import image1 from "../../assets/images/image1.webp";
import image2 from "../../assets/images/image2.webp";
import image3 from "../../assets/images/image3.webp";

const items = [
  {
    key: 1,
    image: image1,
    title: "Nuestro equipo",
    content:
      "Profesionales de gran experiencia en diseño e implementación de soluciones de valor para el negocio.",
  },
  {
    key: 2,
    image: image2,
    title: "Nuestra historia",
    content:
      "Creada para acompañar y acelerar la transformación digital de nuestros clientes.",
  },
  {
    key: 3,
    image: image3,
    title: "Nuestras especialidades",
    content:
      "Gran variedad de servicios de transformación con tecnologías disruptivas.",
  },
];

function AppAbout() {
  return (
    <div id="about" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2 style={{ textAlign: "center", color: "#19c95d" }}>Sobre Nosotros</h2>
        </div>
        <Row gutter={[16, 16]}>
          {items.map((item) => {
            return (
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} key={item.key}>
                <div className="content">
                  <img src={item.image} alt={item.key} />
                  <br /><br />
                  <h3 style={{ color: "#fdfdfd" }}>{item.title}</h3>
                  <div className="contentHolder">
                    <p>{item.content}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}

export default AppAbout;
